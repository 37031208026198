/*
 * form.js - Adds photo widget to sao
 */
'use strict';

Sao.View.Form.Photo = Sao.class_(Sao.View.Form.Widget, {
    class_: 'form-photo',
    init: function(view, attributes) {
        Sao.View.Form.Photo._super.init.call(this, view, attributes);
        this.photo = null;

        this.el = jQuery('<div/>', {
            'class': this.class_,
        });
        this.video = jQuery('<video/>', {
            'autoplay': '',
            'playsinline': '',
            'muted': ''
        }).appendTo(this.el);
        this.image = jQuery('<img/>').appendTo(this.el);
        this.image.hide();
        this.take_photo = jQuery('<button/>', {
            'class': 'btn btn-primary btn-sm form-control',
            'type': 'button'
        }).append("Take Photo").appendTo(this.el);
        this.take_photo.click(this.capture_photo.bind(this));
        this.take_photo.hide();
        this.retake_photo = jQuery('<button/>', {
            'class': 'btn btn-primary btn-sm form-control',
            'type': 'button'
        }).appendTo(this.el);
        this.retake_photo.append("Retake Photo");
        this.retake_photo.click(this.clear_photo.bind(this));
        this.retake_photo.hide();

        this.start_video();
        // TODO: disable_video when video element is removed
    },
    set_value: function() {
        this.field.set_client(this.record, this.get_value());
    },
    get_value: function() {
        if (this.image.is(':visible') && this.photo) {
            return this.photo;
        } else {
            return null;
        }
    },
    display: function() {
        Sao.View.Form.Photo._super.display.call(this);
    },
    set_readonly: function(readonly) {
        Sao.View.Form.Photo._super.set_readonly.call(this, readonly);
    },
    start_video: function() {
        this.video_device = null;
        this.video_devices = [];
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices()
            .then((function(devices) {
                for (var i in devices) {
                    if (devices[i].kind == 'videoinput') {
                        this.video_devices.push(devices[i].deviceId);
                    }
                }
                // if (this.video_devices.length > 1) {
                //     this.switch_camera = jQuery('<button/>', {
                //         'class': 'btn btn-link btn-sm form-control',
                //         'type': 'button'
                //     }).insertBefore(this.video);
                //     this.switch_camera.click(this.switch_video.bind(this));
                // }
                if (this.video_devices) {
                    this.video_device = this.video_devices[0];
                    this.enable_video();
                }
            }).bind(this))
            .catch((function(err) {
                this.el.addClass('error');
            }).bind(this));
        }
    },
    enable_video: function() {
        if (this.video_device) {
            navigator.mediaDevices
                .getUserMedia({
                    video: true,
                    audio: false,
                    deviceId: {exact: this.video_device}})
                .then((function(stream) {
                    this.video[0].srcObject = stream;
                    this.take_photo.show();
                }).bind(this))
                .catch((function(err) {
                    this.el.addClass('error');
                }).bind(this));
        }
    },
    disable_video: function() {
        this.take_photo.hide();
        this.video[0].pause();
        if (this.video[0].srcObject) {
            this.video[0].srcObject.getTracks()[0].stop();
        }
        this.video[0].src = "";
    },
    switch_video: function() {
        this.video.width(this.video.width());
        this.video.height(this.video.height());
        this.disable_video();
        var index = this.video_devices.indexOf(this.video_device);
        if (index < (this.video_devices.length - 1)) {
            index++;
        } else {
            index = 0;
        }
        this.video_device = this.video_devices[index];
        this.enable_video();
    },
    capture_photo: function() {
        var width = this.video[0].videoWidth;
        var height = this.video[0].videoHeight;
        this.video.width(this.video.width());
        this.video.height(this.video.height());

        var canvas = jQuery('<canvas/>')[0];
        canvas.width = width;
        canvas.height = height;
        var context = canvas.getContext('2d');
        context.drawImage(this.video[0], 0, 0, width, height);

        var data_url = canvas.toDataURL('image/jpeg');
        this.image[0].setAttribute("src", data_url);
        this.photo = this._convert_data_url_to_binary(data_url);

        this.video.hide();
        if (this.switch_canmera) {
            this.switch_camera.hide();
        }
        this.image.show();
        this.retake_photo.show();
        this.disable_video();
    },
    _convert_data_url_to_binary: function(data_url) {
        var BASE64_MARKER = ';base64,';
        var base64_index = data_url.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = data_url.substring(base64_index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
        for (var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i);
        }
        return array;
    },
    clear_photo: function() {
        this.enable_video();
        this.video.show();
        if (this.switch_camera) {
            this.switch_camera.show();
        }
        this.image.hide();
        this.retake_photo.hide();
    },
});

Sao.View.FormXMLViewParser.WIDGETS.photo = Sao.View.Form.Photo;
